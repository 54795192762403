import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from "nprogress";
import "nprogress/nprogress.css"
import { rounter } from './router.config'
import store from "../store"

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: rounter(),
});

router.beforeEach((to, from, next) => {
  if (to.meta.checkLogin) {
    const userToken = store.state.user.token;
    if (userToken == "" || userToken == undefined || store.state.user.expireTime < new Date().getTime()/1000) {
      next({ path: "/user/login" });
    } else {
      next();
    }
  }else{
    next();
  }
});

//router.afterEach((to, from, next) => {
router.afterEach(() => {
  NProgress.done();
  NProgress.done();
});

export default router;
