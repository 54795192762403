import axios from "axios";

function postRequest(url, param, selfParam) {
    const self = (selfParam==undefined?this:selfParam)

    var headers = {
        "x-tanbao-client": "webmaster_3.1.2",
        "x-tanbao-deviceid": "KeyDeviceId",
        "x-tanbao-model": "admin",
        "x-tanbao-timestamp": Date.now(),
        "x-tanbao-userid":self.$store.state.user.userId,
        "x-tanbao-token": self.$store.state.user.token,
    }
    
    return axios.post(url,param,{headers:headers}).then(res => {
        if (res.status!=200){
            console.log(res)
            if (res.data != undefined && res.data.code == 100210){
                self.$router.push("/user/login").catch(err=>console.log(err))
                return Promise.reject("登陆失效,重新登陆");
            }
            if(res.data.message!=undefined && res.data.message!=''){
                return Promise.reject(res.data.message);
            }

            return Promise.reject("请求失败");
        }
        return res;
    }).catch(error => {
        self.$message.error(error);

        return new Promise(() => {});
    });
};

export default postRequest;