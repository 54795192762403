
const data = {
    userId: "",
    userName: "",
    nameCity: "",
    headImg: "",
    token: ""
}

const userInfo = {
    namespaced: true,
    state: data,

    mutations: {
        save(state, value) {
            Object.keys(state).forEach(key => {
                state[key] = value[key];
            });
            state.expireTime = new Date().getTime()/1000 + 24*3600
        },
        reset(state) {
            Object.keys(state).forEach(key => {
                state[key] = data[key];
            });

            localStorage.removeItem('heao-user')
        }
    },

    actions: {
        save(state, value) {
            Object.keys(state).forEach(key => {
                state[key] = value[key];
            });
            state.expireTime = new Date().getTime()/1000 + 24*3600
        },
        reset(state) {
            Object.keys(state).forEach(key => {
                state[key] = data[key];
            });
            
            localStorage.removeItem('heao-user')
        }
    },
}

export default userInfo;