import Vue from "vue";
import Antd from "ant-design-vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "ant-design-vue/dist/antd.less";
import request from "@/utils/requests.js";
import ProLayout, { PageHeaderWrapper } from '@ant-design-vue/pro-layout'

Vue.config.productionTip = false;
Vue.use(Antd);

Vue.prototype.$request = request;
Vue.prototype.$antd = Antd;

Vue.component('pro-layout', ProLayout)
Vue.component('page-header-wrapper', PageHeaderWrapper)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
