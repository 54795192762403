<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view />
    </div>
  </a-config-provider>
</template>

<script>
import zhCN from "ant-design-vue/es/locale/zh_CN"; // 在node_modules中找到（可切换各种语言）
export default {
  name: "App",
  data() {
    return {
      locale: zhCN,
    };
  },
};
</script>


<style lang="less"></style>
