import Vue from "vue";
import Vuex from "vuex";
import userInfo from "./module/user.js"
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    user: userInfo,
  },
  plugins: [createPersistedState({
    storage: window.localStorage,
    key: "heao-user"
  })],
});
